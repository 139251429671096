import { BLUE, DARK_GRAY, GREEN, LIGHT_GRAY, PURPLE } from '@/constants/tagColors'

export const OUTGOING_CALL = 'outgoing_call'
export const INCOMING_CALL = 'incoming_call'
export const COMMENT = 'comment'
export const FILE = 'file'
export const SHOW = 'show'
export const CHANGE = 'change'
export const FAVORITE = 'customer_favorite'

const OUTGOING_CALL_TEXT = 'Исх. лид'
const INCOMING_CALL_TEXT = 'Вх. лид'
const OUTGOING_CALL_FULL_TEXT = 'Исходящий лид'
const INCOMING_CALL_FULL_TEXT = 'Входящий лид'
const COMMENT_TEXT = 'Комментарий'
const FILE_TEXT = 'Файл'
const SHOW_TEXT = 'Показ'
const FAVORITE_TEXT = 'Избранное'

export const INTERACTION_HISTORY_TYPES_FORM = [COMMENT, FILE, OUTGOING_CALL, INCOMING_CALL, FAVORITE]

export const INTERACTION_HISTORY_TYPES_GROUP = {
  [OUTGOING_CALL]: { text: OUTGOING_CALL_TEXT, fullText: OUTGOING_CALL_FULL_TEXT, color: BLUE },
  [INCOMING_CALL]: { text: INCOMING_CALL_TEXT, fullText: INCOMING_CALL_FULL_TEXT, color: BLUE },
  [COMMENT]: { text: COMMENT_TEXT, color: LIGHT_GRAY },
  [FILE]: { text: FILE_TEXT, color: PURPLE },
  [SHOW]: { text: SHOW_TEXT, color: GREEN },
  [FAVORITE]: { text: FAVORITE_TEXT, color: DARK_GRAY }
}
export const INTERACTION_HISTORY_DELETE_TYPES = {
  'file.history_change.delete': FILE_TEXT,
  'show.history_change.delete': SHOW_TEXT,
  'incoming_call.history_change.delete': INCOMING_CALL_TEXT,
  'outgoing_call.history_change.delete': OUTGOING_CALL_TEXT,
  'comment.history_change.delete': COMMENT_TEXT,
  'favorite.history_change.delete': FAVORITE_TEXT
}
