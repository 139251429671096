import { toCamelCaseObject, toSnakeCaseObject } from '@/utils/common'
import { getDetailedError } from '@/utils/http'

import { ERROR_CODE_REFRESH } from '@/constants/http'

import ArticlesService from './articles'
import AuthService from './auth'
import AdvertsService from './adverts'
import AgentsService from './agents'
import OwnersService from './owners'
import CustomersService from './customers'
import VillagesService from './villages'
import NotificationsService from './notifications'
import RemindersService from './reminders'
import GeoService from './geo'
import StatisticService from './statistic'
import UploadingService from './uploading'

import client from './client'

export const authService = new AuthService(client)
authService.restoreAuthTokens()
export const advertsService = new AdvertsService(client)
export const agentsService = new AgentsService(client)
export const ownersService = new OwnersService(client)
export const customersService = new CustomersService(client)
export const villagesService = new VillagesService(client)
export const notificationsService = new NotificationsService(client)
export const remindersService = new RemindersService(client)
export const geoService = new GeoService(client)
export const articlesService = new ArticlesService(client)
export const statisticService = new StatisticService(client)
export const uploadingService = new UploadingService(client)

export function initializeInterceptors({ logout }) {
  client.interceptors.request.use(config => ({
    ...config,
    data: config.data instanceof FormData ? config.data : toSnakeCaseObject(config.data)
  }))

  client.interceptors.response.use(
    response => (response && response.data ? toCamelCaseObject(response.data) : response) || {},
    error => {
      const detailedError = getDetailedError(error)
      if (detailedError.status === 401 && detailedError.code === ERROR_CODE_REFRESH) {
        // eslint-disable-next-line no-underscore-dangle
        if (!error.config._retry) {
          return authService.refreshAuthTokens(authService.tokens.refresh).then(() =>
            client({
              ...error.config,
              headers: {
                ...error.config.headers,
                ...client.defaults.headers.common
              },
              _retry: true
            })
          )
        }

        // TODO: придумать как можно более элегантно обыграть выход, (как вариант - через обработку ошибок)
        logout()
      }
      console.warn('[http service] interceptor error:', detailedError.status, error.response, error)
      return Promise.reject(detailedError)
    }
  )
}
