import { ROLE_ADMIN, ROLE_AGENT, ROLE_OWNER } from '@/constants/roles'
import {
  ENTRY_ROUTE_NAME_FOR_REDIRECT,
  LOGIN_ROUTE_NAME,
  OWNER_REGISTRATION_ROUTE_NAME,
  LOGOUT_ROUTE_NAME
} from '@/constants/routes'
import Vue from 'vue'
import store from '@/store'
import { isFunction, isString } from '@/utils/common'
import { analytics } from '@/services/segment'
import { REALTY_STATUS_ACTIVE, REALTY_STATUS_ARCHIVE, REALTY_STATUS_DRAFT } from '@/constants/statuses/realty'
import { OWNER_STATUS_ACTIVE, OWNER_STATUS_ARCHIVE } from '@/constants/statuses/owners'
import { CUSTOMER_STATUS_ACTIVE, CUSTOMER_STATUS_ARCHIVE } from '@/constants/statuses/customers'
import { USER_STATUS_ACTIVE, USER_STATUS_ARCHIVE } from '@/constants/statuses/users'
import { VILLAGE_ACTIVE_STATUS, VILLAGE_ARCHIVE_STATUS, VILLAGE_DRAFT_STATUS } from '@/constants/statuses/village'
import { ARTICLE_STATUS_ACTIVE, ARTICLE_STATUS_ARCHIVE, ARTICLE_STATUS_DRAFT } from '@/constants/statuses/article'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import { GENERAL } from '@/constants/dashboard'
import {
  auth,
  logout,
  authNotRequired,
  loadProfile,
  checkRole,
  redirectByPermission,
  setDynamicSeoTitle,
  signInAnalytics,
  redirectIfNotNmh,
  loadTenantInfo
} from './middleware'

async function watchDynamicSeoTitle(component, watchQueryProperty) {
  const localComponent = await component
  // eslint-disable-next-line vue/require-name-property
  return Vue.extend({
    ...localComponent.default,
    watch: {
      ...localComponent.default.watch,
      [`$route.query.${watchQueryProperty}`]: {
        handler() {
          const { seoTitle, segmentAnalytics } = this.$route.meta
          let localSeoTitle = ''
          if (isString(seoTitle)) {
            localSeoTitle = seoTitle
          } else if (isFunction(seoTitle)) {
            localSeoTitle = seoTitle(this.$route)
          }
          document.title = localSeoTitle
          if (segmentAnalytics && localSeoTitle) {
            const { name } = segmentAnalytics
            analytics.page(name, { title: localSeoTitle })
          }
        }
      }
    }
  })
}

const DASHBOARD_TAB_TITLES = {
  calls: 'Лиды',
  general: 'Общая',
  shows: 'Показы',
  objects: 'Объекты'
}
const OBJECT_REALTY_TAB_TITLES = {
  calls: 'Лиды',
  shows: 'Показы',
  price: 'Стоимость'
}
const OBJECTS_REALTY_LIST_TAB_TITLES = {
  [REALTY_STATUS_ACTIVE]: 'Актуальное',
  [REALTY_STATUS_DRAFT]: 'Черновики',
  [REALTY_STATUS_ARCHIVE]: 'Архив'
}
const VILLAGES_LIST_TAB_TITLES = {
  [VILLAGE_ACTIVE_STATUS]: 'Актуальное',
  [VILLAGE_DRAFT_STATUS]: 'Черновики',
  [VILLAGE_ARCHIVE_STATUS]: 'Архив'
}
const ARTICLES_LIST_TAB_TITLES = {
  [ARTICLE_STATUS_ACTIVE]: 'Актуальное',
  [ARTICLE_STATUS_DRAFT]: 'Черновики',
  [ARTICLE_STATUS_ARCHIVE]: 'Архив'
}
const OWNERS_LIST_TAB_TITLES = {
  [OWNER_STATUS_ACTIVE]: 'Актуальное',
  [OWNER_STATUS_ARCHIVE]: 'Архив'
}
const CUSTOMERS_LIST_TAB_TITLES = {
  [CUSTOMER_STATUS_ACTIVE]: 'Актуальное',
  [CUSTOMER_STATUS_ARCHIVE]: 'Архив'
}
const USERS_LIST_TAB_TITLES = {
  [USER_STATUS_ACTIVE]: 'Актуальное',
  [USER_STATUS_ARCHIVE]: 'Архив'
}

const titleOrganisation = store.getters[`${MODULE_SESSION}/isNmh`] ? 'NMH' : 'Agentify'

export default [
  {
    path: '/',
    component: () => import(/* webpackChunkName: 'auth-layout' */ '@/layouts/global/AuthLayout.vue'),
    props: to => {
      return to.meta?.layoutProps
    },
    redirect: LOGIN_ROUTE_NAME,
    meta: {
      middleware: [authNotRequired, loadTenantInfo]
    },
    children: [
      {
        path: '/auth/callback',
        name: 'auth-callback',
        component: () => import(/* webpackChunkName: 'auth-callback' */ '@/views/AuthCallback.vue'),
        meta: {
          seoTitle: `Авторизация... | ${titleOrganisation}`,
          middleware: [authNotRequired, loadTenantInfo]
        }
      },
      {
        path: '/login',
        name: LOGIN_ROUTE_NAME,
        component: () => import(/* webpackChunkName: 'login-select-role' */ '@/views/LoginSelectRole.vue'),
        meta: {
          seoTitle: `Вход | ${titleOrganisation}`,
          segmentAnalytics: { title: 'Login', name: 'Login' }
        }
      },
      {
        path: '/login/staff/',
        name: 'login-staff',
        component: () => import(/* webpackChunkName: 'login-staff' */ '@/views/LoginStaff.vue'),
        meta: {
          seoTitle: `Вход | Агент | ${titleOrganisation}`,
          segmentAnalytics: { title: 'Login Staff', name: 'Login Staff' }
        }
      },
      {
        path: '/login/owner/',
        name: 'login-owner',
        component: () => import(/* webpackChunkName: 'login-owner' */ '@/views/LoginOwner.vue'),
        meta: {
          seoTitle: `Вход | Собственник | ${titleOrganisation}`,
          segmentAnalytics: { title: 'Login Owner', name: 'Login Owner' }
        }
      },
      {
        path: '/reset-password/',
        name: 'password-reset',
        component: () => import(/* webpackChunkName: 'password-reset' */ '@/views/PasswordReset.vue')
      },
      {
        path: '/notice-reset-password',
        name: 'notice-reset-password',
        component: () => import(/* webpackChunkName: 'notice-reset-password' */ '@/views/NoticeResetPassword.vue'),
        meta: { layoutProps: { hideLogo: true } }
      },
      {
        path: '/reset-password-confirm/:uid/:token',
        name: 'reset-password-confirm',
        component: () => import(/* webpackChunkName: 'create-new-password' */ '@/views/CreateNewPassword.vue')
      },
      {
        path: '/set-password-confirm/:uid/:token',
        name: 'set-password-confirm',
        component: () => import(/* webpackChunkName: 'create-new-password' */ '@/views/CreateNewPassword.vue'),
        props: { isNewUser: true }
      }
    ]
  },
  {
    path: '/registration',
    name: OWNER_REGISTRATION_ROUTE_NAME,
    component: () => import(/* webpackChunkName: 'owner-registration' */ '@/views/OwnerRegistration.vue'),
    meta: {
      middleware: [authNotRequired],
      segmentAnalytics: { title: 'Property Owner Sign Up', name: 'Property Owner Sign Up' }
    }
  },
  {
    path: '/admin-panel-entry-route',
    name: ENTRY_ROUTE_NAME_FOR_REDIRECT,
    meta: {
      middleware: [auth, loadTenantInfo, loadProfile, signInAnalytics, redirectByPermission]
    }
  },
  {
    path: '/logout',
    name: LOGOUT_ROUTE_NAME,
    meta: {
      middleware: [logout]
    }
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: 'dashboard-layout' */ '@/layouts/global/MainLayout.vue'),
    props: to => {
      return to.meta?.layoutProps
    },
    meta: {
      middleware: [auth, loadProfile]
    },
    children: [
      {
        path: '/calls',
        name: 'calls',
        component: () => import(/* webpackChunkName: 'calls' */ '@/views/Calls.vue'),
        meta: {
          layoutProps: { hideFooter: true, withoutBottomPadding: true },
          middleware: [checkRole([ROLE_ADMIN, ROLE_AGENT])],
          seoTitle: `Лиды | ${titleOrganisation}`,
          segmentAnalytics: { title: 'Leads List', name: 'Leads List' }
        }
      },
      {
        path: '/shows',
        name: 'shows',
        component: () => import(/* webpackChunkName: 'shows' */ '@/views/Shows.vue'),
        meta: {
          layoutProps: { hideFooter: true, withoutBottomPadding: true },
          middleware: [checkRole([ROLE_ADMIN, ROLE_AGENT])],
          seoTitle: `Показы | ${titleOrganisation}`,
          segmentAnalytics: { title: 'Tours List', name: 'Tours List' }
        }
      },
      {
        path: '/owners',
        name: 'owners',
        component: () => watchDynamicSeoTitle(import(/* webpackChunkName: 'owners' */ '@/views/Owners.vue'), 'status'),
        meta: {
          layoutProps: { hideFooter: true, withoutBottomPadding: true },
          middleware: [checkRole([ROLE_ADMIN, ROLE_AGENT])],
          seoTitle(route) {
            return `${
              OWNERS_LIST_TAB_TITLES[route.query?.status || OWNER_STATUS_ACTIVE]
            } | Собственники | ${titleOrganisation}`
          },
          segmentAnalytics: { title: 'Property Owner List', name: 'Property Owner List', isDynamic: true }
        }
      },
      {
        path: '/owners/edit/:ownerId?',
        name: 'owner-edit',
        component: () => import(/* webpackChunkName: 'owner-edit' */ '@/views/OwnerEdit.vue'),
        meta: {
          middleware: [checkRole([ROLE_ADMIN, ROLE_AGENT]), setDynamicSeoTitle(`Собственники | ${titleOrganisation}`)],
          segmentAnalytics: { title: 'Property Owner Profile', name: 'Property Owner Profile' }
        }
      },
      {
        path: '/customers',
        name: 'customers',
        component: () =>
          watchDynamicSeoTitle(import(/* webpackChunkName: 'customers' */ '@/views/Customers.vue'), 'status'),
        meta: {
          layoutProps: { hideFooter: true, withoutBottomPadding: true },
          middleware: [checkRole([ROLE_ADMIN, ROLE_AGENT])],
          seoTitle(route) {
            return `${
              CUSTOMERS_LIST_TAB_TITLES[route.query?.status || CUSTOMER_STATUS_ACTIVE]
            } | Покупатели | ${titleOrganisation}`
          },
          segmentAnalytics: { title: 'Customer List', name: 'Customer List', isDynamic: true }
        }
      },
      {
        path: '/customers/edit/:customerId?',
        name: 'customer-edit',
        component: () => import(/* webpackChunkName: 'customer-edit' */ '@/views/CustomerEdit.vue'),
        meta: {
          middleware: [checkRole([ROLE_ADMIN, ROLE_AGENT]), setDynamicSeoTitle(`Покупатели | ${titleOrganisation}`)],
          segmentAnalytics: { title: 'Customer Profile', name: 'Customer Profile' }
        }
      },
      {
        path: '/users',
        name: 'users',
        component: () => watchDynamicSeoTitle(import(/* webpackChunkName: 'users' */ '@/views/Users.vue'), 'status'),
        meta: {
          middleware: [checkRole([ROLE_ADMIN])],
          layoutProps: { hideFooter: true, withoutBottomPadding: true },
          seoTitle(route) {
            return `${
              USERS_LIST_TAB_TITLES[route.query?.status || USER_STATUS_ACTIVE]
            } | Пользователи | ${titleOrganisation}`
          },
          segmentAnalytics: { title: 'Users List', name: 'Users List', isDynamic: true }
        }
      },
      {
        path: '/villages',
        name: 'villages',
        component: () =>
          watchDynamicSeoTitle(import(/* webpackChunkName: 'villages' */ '@/views/Villages.vue'), 'status'),
        meta: {
          middleware: [checkRole([ROLE_ADMIN]), redirectIfNotNmh],
          seoTitle(route) {
            return `${
              VILLAGES_LIST_TAB_TITLES[route.query?.status || VILLAGE_ACTIVE_STATUS]
            } | Коттеджные поселки | ${titleOrganisation}`
          },
          segmentAnalytics: { title: 'Villages', name: 'Villages', isDynamic: true }
        }
      },
      {
        path: '/villages/edit/:villageId?',
        name: 'villages-edit',
        component: () => import(/* webpackChunkName: 'villages-edit' */ '@/views/VillagesEdit.vue'),
        props: true,
        meta: {
          middleware: [checkRole([ROLE_ADMIN]), redirectIfNotNmh]
        }
      },
      {
        path: '/reminders',
        name: 'reminders',
        component: () => import(/* webpackChunkName: 'reminders' */ '@/views/Reminders.vue'),
        meta: {
          layoutProps: { hideFooter: true },
          middleware: [checkRole([ROLE_ADMIN, ROLE_AGENT])],
          seoTitle: `Напоминания | ${titleOrganisation}`,
          segmentAnalytics: { title: 'Reminders', name: 'Reminders' }
        }
      },
      {
        path: '/notifications',
        name: 'notifications',
        component: () => import(/* webpackChunkName: 'notifications' */ '@/views/Notifications.vue'),
        meta: {
          middleware: [checkRole([ROLE_ADMIN, ROLE_AGENT])],
          seoTitle: `Уведомления | ${titleOrganisation}`,
          segmentAnalytics: { title: 'Notification List', name: 'Notification List' }
        }
      },
      {
        path: '/objects-realty',
        name: 'objects-realty',
        component: () =>
          watchDynamicSeoTitle(import(/* webpackChunkName: 'objects-realty' */ '@/views/ObjectsRealty.vue'), 'status'),
        meta: {
          middleware: [checkRole([ROLE_ADMIN, ROLE_AGENT, ROLE_OWNER])],
          seoTitle(route) {
            return `${
              OBJECTS_REALTY_LIST_TAB_TITLES[route.query?.status || REALTY_STATUS_ACTIVE]
            } | Объекты недвижимости | ${titleOrganisation}`
          },
          segmentAnalytics: { title: 'Listings List', name: 'Listings List', isDynamic: true }
        }
      },
      {
        path: '/objects-realty/edit/:objectId?/:status?/:initialNotificationId?',
        name: 'objects-realty-edit',
        component: () => import(/* webpackChunkName: 'objects-realty-edit' */ '@/views/ObjectsRealtyEdit.vue'),
        meta: {
          middleware: [checkRole([ROLE_ADMIN, ROLE_AGENT])],
          seoTitle: `Редактирование объекта | ${titleOrganisation}`,
          segmentAnalytics: { title: 'Listing Editing', name: 'Listing Editing' }
        }
      },
      {
        path: '/objects-realty/stat/:objectId',
        name: 'objects-realty-stat',
        component: () =>
          watchDynamicSeoTitle(
            import(/* webpackChunkName: 'objects-realty-statistic' */ '@/views/ObjectsRealtyStatistic.vue'),
            'tab'
          ),
        meta: {
          middleware: [checkRole([ROLE_ADMIN, ROLE_AGENT, ROLE_OWNER])],
          seoTitle(query) {
            if (query.tab) {
              return `${
                OBJECT_REALTY_TAB_TITLES[query.tab]
              } | Объекты недвижимости | Анализ объекта недвижимости | ${titleOrganisation}`
            }
            return ''
          },
          segmentAnalytics: { title: 'Listing Analytics', name: 'Listing Analytics', isDynamic: true }
        }
      },
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () =>
          watchDynamicSeoTitle(import(/* webpackChunkName: 'dashboard' */ '@/views/Dashboard.vue'), 'tab'),
        meta: {
          middleware: [checkRole([ROLE_ADMIN, ROLE_AGENT])],
          seoTitle(route) {
            return `${DASHBOARD_TAB_TITLES[route.query?.tab || GENERAL]} | Аналитика агентства | ${titleOrganisation}`
          },
          segmentAnalytics: { title: 'Analytics', name: 'Analytics', isDynamic: true }
        }
      },
      {
        path: '/articles',
        name: 'articles',
        component: () =>
          watchDynamicSeoTitle(import(/* webpackChunkName: 'articles' */ '@/views/Articles.vue'), 'status'),
        meta: {
          middleware: [checkRole([ROLE_ADMIN, ROLE_AGENT]), redirectIfNotNmh],
          seoTitle(route) {
            return `${
              ARTICLES_LIST_TAB_TITLES[route.query?.status || ARTICLE_STATUS_ACTIVE]
            } | Блог | ${titleOrganisation}`
          },
          segmentAnalytics: { title: 'Articles List', name: 'Articles List', isDynamic: true }
        }
      },
      {
        path: '/articles/edit/:articleId?',
        name: 'articles-edit',
        component: () => import(/* webpackChunkName: 'article-edit' */ '@/views/ArticlesEdit.vue'),
        meta: {
          middleware: [checkRole([ROLE_ADMIN, ROLE_AGENT]), redirectIfNotNmh]
        }
      },
      {
        path: '/404',
        name: '404',
        props: { type: '404' },
        meta: { layoutProps: { hideFooter: true } },
        component: () => import(/* webpackChunkName: 'error' */ '@/views/Error.vue')
      },
      {
        path: '/network-issue',
        name: 'network-issue',
        props: { type: '500' },
        meta: { layoutProps: { hideFooter: true } },
        component: () => import(/* webpackChunkName: "network-issue" */ '@/views/Error.vue')
      }
    ]
  },
  {
    path: '*',
    redirect: { name: '404' }
  }
]
