import { LOADING, LOADED, SET_ERROR } from '@/store/common/mutations.types'
import { pickCommonMutations } from '@/store/common/mutations'
import { LOGOUT, SAVE_SECTION_INFO } from '@/store/common/actions.types'
import { advertsService } from '@/services/http'
import { fillModel } from '@/utils/common'
import { loadStorageItem } from '@/services/storage'
import { FINISHED_STATUS } from '@/constants/photoUploadStatuses'
import { SALE_SOURCE_OPTIONS } from '@/constants/objectRealtyOptions'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import * as moduleTypes from './realty.types'
/*
  // Extra fields
  "slug": "string",
  "is_deleted": true,
*/

function generateBasicInfoModel() {
  return {
    isPublished: false,
    isSpecial: false,
    xmlYandex: false,
    xmlCian: false,
    xmlFacebook: false,
    xmlAvito: false,
    xmlYandexDirect: false,
    xmlDomClick: false,
    publishTerms: null,
    cianAuctionBid: null,
    status: null,
    soldoutAt: null,
    saleSource: ''
  }
}

function generalAboutObject() {
  return {
    dealType: null,
    houseType: null,
    landCadastralNumber: null,
    houseCadastralNumber: null,
    apartmentCadastralNumber: null,
    category: null,
    avitoWallsType: null,
    facingType: null,
    plotType: null,
    isElectricity: false,
    isGas: false,
    isSewerage: false,
    isWater: false,
    wcCount: null,
    bedroomsCount: null,
    roomCount: null,
    floors: null,
    housingComplexName: null,
    housingComplexId: null,
    layers: null,
    parkingLot: null,
    elevatorCount: null,
    serviceLiftCount: null,
    windowsOutdoor: false,
    windowsCourtyard: false,
    deposit: null,
    prepaymentMonths: null,
    realtyPurpose: null,
    rentDuration: null,
    communalPaymentsIncluded: null,
    countersIncluded: null,
    youtubeUrl: null,
    price: null,
    auxiliaryCurrency: null,
    auxiliaryPrice: null,
    buildYear: null,
    area: null,
    livingArea: null,
    kitchenArea: null,
    plottage: null,
    mortgage: null,
    loggiaCount: null,
    balconiesCount: null,
    separateWcCount: null,
    combinedWcCount: null,
    renovationType: null,
    floor: null,
    tags: []
  }
}

function generateDescriptionInfoModel() {
  return {
    description: null,
    name: null
  }
}

function generateSeoInfoModel() {
  return {
    seoTitle: null,
    seoDescription: null
  }
}

function generatePlacementInfoModel() {
  return {
    address: null,
    latitude: null,
    longitude: null,
    settlement: null,
    villageName: null,
    villageCianId: null,
    village: null
  }
}

function generatePhotosModel() {
  return {
    photos: {},
    advertMediaOrders: {},
    deletedPhotos: [],
    draftMediaOrders: {},
    draftDeletedPhotos: []
  }
}

function generateAgentsModel() {
  return {
    agent: null
  }
}

function generateLinksModel() {
  return {
    cianUrl: null,
    yandexUrl: null,
    domClickUrl: null
  }
}

export default {
  namespaced: true,

  state: {
    loading: false,
    id: null,
    owner: null,
    photos: [],
    mediaDrafts: [],
    uploadingPhotosStatus: FINISHED_STATUS,
    [moduleTypes.BASIC_INFO]: generateBasicInfoModel(),
    [moduleTypes.ABOUT_OBJECT]: generalAboutObject(),
    [moduleTypes.DESCRIPTION_INFO]: generateDescriptionInfoModel(),
    [moduleTypes.SEO_INFO]: generateSeoInfoModel(),
    [moduleTypes.PLACEMENT_INFO]: generatePlacementInfoModel(),
    [moduleTypes.PHOTOS]: generatePhotosModel(),
    [moduleTypes.RESPONSIBLE_AGENT]: generateAgentsModel(),
    [moduleTypes.LINKS]: generateLinksModel()
  },

  getters: {
    [moduleTypes.FULL_REALTY_MODEL]: state => {
      return {
        ...state[moduleTypes.BASIC_INFO],
        ...state[moduleTypes.ABOUT_OBJECT],
        ...state[moduleTypes.DESCRIPTION_INFO],
        ...state[moduleTypes.SEO_INFO],
        ...state[moduleTypes.PLACEMENT_INFO],
        ...state[moduleTypes.PHOTOS],
        ...state[moduleTypes.RESPONSIBLE_AGENT],
        ...state[moduleTypes.LINKS]
      }
    },
    [moduleTypes.SALE_SOURCES]: (state, getters, rootState, rootGetters) => {
      return rootGetters[`${MODULE_SESSION}/isNmh`]
        ? SALE_SOURCE_OPTIONS
        : SALE_SOURCE_OPTIONS.map(source => {
            return {
              ...source,
              label: source.label.replace('NMH', rootGetters[`${MODULE_SESSION}/tenantOrganizationName`] ?? '')
            }
          })
    }
  },

  mutations: {
    ...pickCommonMutations([LOADING, LOADED, SET_ERROR]),

    [moduleTypes.SET_REALTY]: (state, realty) => {
      const basicModel = fillModel(generateBasicInfoModel(), realty)
      const aboutObject = fillModel(generalAboutObject(), realty)
      const descriptionModel = fillModel(generateDescriptionInfoModel(), realty)
      const seoModel = fillModel(generateSeoInfoModel(), realty)
      const placementModel = fillModel(generatePlacementInfoModel(), realty)
      const agentsModel = fillModel(generateAgentsModel(), realty)
      const linksModel = fillModel(generateLinksModel(), realty)

      state[moduleTypes.BASIC_INFO] = basicModel
      state[moduleTypes.ABOUT_OBJECT] = aboutObject
      state[moduleTypes.DESCRIPTION_INFO] = descriptionModel
      state[moduleTypes.SEO_INFO] = seoModel
      state[moduleTypes.PLACEMENT_INFO] = placementModel
      state[moduleTypes.RESPONSIBLE_AGENT] = agentsModel
      state[moduleTypes.PHOTOS] = generatePhotosModel()
      state[moduleTypes.LINKS] = linksModel
      state.photos = realty.photos
      state.mediaDrafts = realty.mediaAdd?.map(draft => {
        const image = `${draft.image}?token=${loadStorageItem('accessToken')}`
        return { ...draft, image }
      })
      state.id = realty.id
      state.owner = realty.owner
    },
    [moduleTypes.PATCH_SECTION_INFO]: (state, { type, sectionInfo }) => {
      state[type] = sectionInfo
    },
    [moduleTypes.RESET_STATE_MUTATION]: state => {
      state[moduleTypes.BASIC_INFO] = generateBasicInfoModel()
      state[moduleTypes.ABOUT_OBJECT] = generalAboutObject()
      state[moduleTypes.DESCRIPTION_INFO] = generateDescriptionInfoModel()
      state[moduleTypes.SEO_INFO] = generateSeoInfoModel()
      state[moduleTypes.PLACEMENT_INFO] = generatePlacementInfoModel()
      state[moduleTypes.PHOTOS] = generatePhotosModel()
      state[moduleTypes.RESPONSIBLE_AGENT] = generateAgentsModel()
      state[moduleTypes.LINKS] = generateLinksModel()
      state.loading = false
      state.id = null
      state.photos = []
      state.mediaDrafts = []
      state.owner = null
    },
    [moduleTypes.SET_UPLOADING_PHOTOS_STATUS]: (state, status) => {
      state.uploadingPhotosStatus = status
    }
  },

  actions: {
    [moduleTypes.UPDATE_UPLOADING_PHOTOS_STATUS]: ({ commit }, status) => {
      commit(moduleTypes.SET_UPLOADING_PHOTOS_STATUS, status)
    },
    [SAVE_SECTION_INFO]: ({ commit }, { type, sectionInfo }) => {
      commit(moduleTypes.PATCH_SECTION_INFO, { type, sectionInfo })
    },
    [moduleTypes.RESET_STATE]: ({ commit }) => {
      commit(moduleTypes.RESET_STATE_MUTATION)
    },

    [moduleTypes.FETCH_REALTY_BY_ID]: ({ commit }, { id, status }) => {
      commit(LOADING)
      return advertsService
        .getById({ id, status })
        .then(result => {
          commit(moduleTypes.SET_REALTY, result)
        })
        .finally(() => {
          commit(LOADED)
        })
    },
    [moduleTypes.CREATE_REALTY]: ({ commit }, realty) => {
      commit(LOADING)
      return advertsService
        .create(realty)
        .then(result => {
          commit(moduleTypes.SET_REALTY, result)
        })
        .finally(() => commit(LOADED))
    },
    [moduleTypes.UPDATE_REALTY]: ({ state, commit }, realty) => {
      const { id } = state

      commit(LOADING)
      return advertsService
        .update(id, realty)
        .then(result => {
          commit(moduleTypes.SET_REALTY, result)
        })
        .finally(() => commit(LOADED))
    },

    [LOGOUT]: ({ dispatch }) => {
      dispatch(moduleTypes.RESET_STATE)
    }
  }
}
