// STORE TYPES
export const MODULE_SESSION = 'session'

//  ACTION TYPES
export const FETCH_USER_INFO = 'fetchUserInfo'
export const FETCH_TENANT_INFO = 'fetchTenantInfo'

// GETTERS TYPES
export const IS_ROLE_ADMIN = 'isRoleAdmin'

// MUTATIONS TYPES
export const SET_TENANT_MODEL = 'setTenantModel'

export const VERIFY_AUTH_TOKEN = 'verifyAuthToken'
export const SET_AUTH_TOKEN = 'setAuthToken'
export const SET_TOKEN = 'setToken'
