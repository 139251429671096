import Vue from 'vue'
import VTooltip from 'v-tooltip'
import { LOGOUT_ROUTE_NAME } from '@/constants/routes'
import { initializeInterceptors } from '@/services/http'
import { Table, TableColumn, Calendar, Progress } from 'element-ui'
import VeeValidate from './plugins/vee-validate'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/scss/base.scss'

Vue.config.productionTip = false

function logout() {
  router.push({ name: LOGOUT_ROUTE_NAME })
}

initializeInterceptors({ logout })

Vue.use(VTooltip)
Vue.use(VeeValidate)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Calendar)
Vue.use(Progress)

// eslint-disable-next-line vue/require-name-property
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
