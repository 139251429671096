import { loadStorageItem, saveStorageItem, removeStorageItem } from '@/services/storage'
import { flatObject, getFormDataFrom, toSnakeCaseObject } from '@/utils/common'
import { USER_STATUS_ARCHIVE } from '@/constants/statuses/users'
import BaseHttpService from './base'
import { setHeader, unsetHeader } from './client'

const AUTH_HEADER = 'Authorization'

const ACCESS_TOKEN = 'accessToken'
const REFRESH_TOKEN = 'refreshToken'

class AuthService extends BaseHttpService {
  tokens = {
    access: null,
    refresh: null
  }

  isTokensSync = true

  setTokensSync(isTokensSync) {
    this.isTokensSync = isTokensSync
  }

  getTokensSync() {
    return this.isTokensSync
  }

  hasAuthHeader() {
    return !!this.client.defaults.headers.common[AUTH_HEADER]
  }

  generateAuthHeader(accessToken = this.tokens.access) {
    return `Bearer ${accessToken}`
  }

  setAuthTokens(tokens) {
    this.tokens = tokens

    setHeader(AUTH_HEADER, this.generateAuthHeader())

    if (this.isTokensSync) {
      saveStorageItem(ACCESS_TOKEN, tokens.access)
      saveStorageItem(REFRESH_TOKEN, tokens.refresh)
    }
  }

  removeAuthTokens() {
    this.tokens = { access: null, refresh: null }

    unsetHeader(AUTH_HEADER)

    if (this.isTokensSync) {
      removeStorageItem(ACCESS_TOKEN)
      removeStorageItem(REFRESH_TOKEN)
    }
  }

  restoreAuthTokens() {
    const tokens = {
      access: loadStorageItem(ACCESS_TOKEN),
      refresh: loadStorageItem(REFRESH_TOKEN)
    }
    if (tokens.access && tokens.refresh) {
      this.setAuthTokens(tokens)
    }
  }

  refreshAuthTokens(refresh) {
    return this.client
      .post('/auth/token/refresh/', { refresh })
      .then(tokens => {
        this.setAuthTokens(tokens)
      })
      .catch(error => {
        this.removeAuthTokens()
        throw error
      })
  }

  fetchUserInfo() {
    return this.client.get('/auth/info/')
  }

  fetchTenantInfo() {
    return this.client.get('/auth/tenant/info/')
  }

  updateUser(id, { email, phone, position, role, name, photo, isArchived }) {
    const formData = getFormDataFrom(
      flatObject(
        toSnakeCaseObject({
          email,
          phone,
          position,
          role,
          name,
          photo,
          isArchived
        })
      )
    )

    return this.client.patch(`/auth/staff/${id}/`, formData)
  }

  createUser({ email, phone, position, role, name, photo }) {
    const formData = getFormDataFrom(
      flatObject(
        toSnakeCaseObject({
          email,
          phone,
          position,
          role,
          name,
          photo
        })
      )
    )
    return this.client.post('/auth/staff/', formData)
  }

  cancelUserInvite(id) {
    return this.client.delete(`/auth/staff/${id}/`)
  }

  getUsersList({ limit, offset, status, cancelToken }) {
    const params = toSnakeCaseObject({
      limit,
      offset
    })
    if (status === USER_STATUS_ARCHIVE) {
      return this.client.get('/auth/staff/archived/', { params, cancelToken })
    }
    return this.client.get('/auth/staff/', { params, cancelToken })
  }

  authStaff(user) {
    return this.client.post('/auth/staff/token/', user)
  }

  authOwner(phone, code) {
    return this.client.post('/auth/owner/token/', { phone, code })
  }

  sendCodeToOwnerPhone(phone) {
    return this.client.post('/auth/owner/send-code/', { phone })
  }

  sendRegistrationCodeToOwnerPhone(phone) {
    return this.client.post('/auth/owner/register/send-code/', { phone })
  }

  verifyRegistrationCode(phone, code) {
    return this.client.post('/auth/owner/register/verify-code/', { phone, code })
  }

  registerOwner({ name, phone, email, landCadastralNumber, houseCadastralNumber }) {
    return this.client.post('/auth/owner/register/', { name, phone, email, landCadastralNumber, houseCadastralNumber })
  }

  getOffer() {
    return this.client.get('/public_offer/')
  }

  resetPassword(email) {
    return this.client.post('/auth/password/reset/', { email })
  }

  setPassword(id, email) {
    return this.client.post(`/auth/staff/${id}/set_password/`, { email })
  }

  confirmPassword({ uid, token, newPassword }) {
    return this.client.post('/auth/password/confirm/', { uid, token, newPassword })
  }

  select({ phone, name, role }) {
    const params = new URLSearchParams()
    if (phone) {
      params.append('phone', phone)
    }
    if (name) {
      params.append('name', name)
    }
    if (role) {
      if (Array.isArray(role)) {
        role.map(roleName => params.append('role', roleName))
      } else {
        params.append('role', role)
      }
    }
    return this.client.get('/auth/select/', { params })
  }
}

export default AuthService
